import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	auth,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	db,
	updateDoc,
	doc,
  getDoc,
} from "../../../firebase-config";
import styles from "./SignUpForm.module.css";

const LoginForm = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const userCredential = await signInWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;

			if (!user.emailVerified) {
				setError("Please verify your email before logging in.");
				setLoading(false);
				return;
			}

			// Now store the School Email after successful login and verification
			await updateDoc(doc(db, "Students", user.uid), {
				schoolEmail: user.email,
			});

			const userDocRef = doc(db, "Students", user.uid);
			const userDoc = await getDoc(userDocRef);

			if (userDoc.exists()) {
				const userData = userDoc.data();
				const profileCreated = userData.profileCreated;

				if (!profileCreated) {
					// If it's the user's first time, navigate to the first-time setup page
					navigate("/students-p2");
				} else {
					// Navigate to the normal student page
					navigate("/students-p3");
				}
			} else {
				setError("User data not found. Please contact support.");
			}
		} catch (error) {
			setError("Error during login. Please try again");
		} finally {
			setLoading(false);
		}
	};

	const handleForgotPassword = async () => {
		if (!email) {
			setError("Please enter your email to reset your password.");
			return;
		}

		try {
			await sendPasswordResetEmail(auth, email);
			setMessage("Password reset email sent. Please check your inbox.");
		} catch (error) {
			setError("Error sending password reset email. Please try again.");
		}
	};

	return (
		<form className={styles.signUpForm} onSubmit={handleLogin}>
			<div className={styles.emailWrapper}>
				<input
					type="email"
					placeholder="School Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className={styles.input}
					required
					disabled={loading}
				/>
			</div>
			<div className={styles.passwordWrapper}>
				<input
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className={styles.input}
					required
					disabled={loading}
				/>
			</div>
			<div className={styles.switchTextWrapper}>
				<p className={styles.switchText}>
					Forgot your password?{" "}
					<span className={styles.switchLink} onClick={handleForgotPassword}>
						Reset
					</span>
				</p>
			</div>
			{error && <p className={styles.error}>{error}</p>}
			{message && <p className={styles.message}>{message}</p>}
			<div className={styles.submissionButton}>
				<button type="submit" className={styles.done} disabled={loading}>
					{loading ? (
						<span className={styles.spinner}></span>
					) : (
						<b className={styles.createProfile}>Sign In</b>
					)}
				</button>
			</div>
		</form>
	);
};

export default LoginForm;
