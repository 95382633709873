import styles from "./CreateProfile.module.css";
import React, { useState, useEffect } from "react";
import schoolsData from "../../../datasets/schools.json";
import majorsData from "../../../datasets/majors.json";
import clubsData from "../../../datasets/clubs.json";
import locationData from "../../../datasets/location.json"; // New import for location data
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import * as mammoth from "mammoth";
import { useNavigate, useLocation } from "react-router-dom";
import {
	storage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	auth,
	db,
	doc,
	updateDoc,
	getDoc,
} from "../../../firebase-config"; // Ensure correct import paths

const CreateProfile = () => {
	const navigate = useNavigate();
	const routeLocation = useLocation(); // Get state from Navbar
	const isUpdating = routeLocation.state?.isUpdating || false; // Check if we are in update mode
	const [userId, setUserId] = useState("");
	const [age, setAge] = useState("");
	const [gender, setGender] = useState("");
	const [location, setLocation] = useState("");
	const [ethnicity, setEthnicity] = useState("");
	const [clubs, setClubs] = useState([]);
	const [clubInput, setClubInput] = useState("");
	const [school, setSchool] = useState("");
	const [major, setMajor] = useState("");

	const [filteredSchools, setFilteredSchools] = useState([]);
	const [filteredMajors, setFilteredMajors] = useState([]);
	const [filteredClubs, setFilteredClubs] = useState([]);
	const [filteredCities, setFilteredCities] = useState([]); // New state for filtered cities
	const [filteredEthnicities, setFilteredEthnicities] = useState([]); // New state for filtered ethnicities
	const [showCityDropdown, setShowCityDropdown] = useState(false); // State to show city dropdown
	const [showEthnicityDropdown, setShowEthnicityDropdown] = useState(false); // State to show ethnicity dropdown

	const [isHeadshotUploaded, setIsHeadshotUploaded] = useState(false);
	const [isResumeUploaded, setIsResumeUploaded] = useState(false);

	pdfjsLib.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.mjs";

	const ethnicities = [
		"Indigenous or Native American",
		"Asian",
		"Black",
		"Pacific Islander",
		"White",
		"Hispanic or Latino",
		"Middle Eastern",
		"Mixed",
		"Other",
	];

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUserId(user.uid); // Set user ID

				// Fetch user data from Firestore
				const userDocRef = doc(db, "Students", user.uid);
				const userDoc = await getDoc(userDocRef);

				if (userDoc.exists()) {
					const userData = userDoc.data();
					// Set each state value to the Firestore data, or keep it empty if not available
					setAge(userData.age || "");
					setGender(userData.gender || "");
					setLocation(userData.location || "");
					setEthnicity(userData.ethnicity || "");
					setClubs(userData.clubs || []);
					setSchool(userData.school || "");
					setMajor(userData.major || "");
					setIsHeadshotUploaded(!!userData.headshot); // Check if headshot exists
					setIsResumeUploaded(!!userData.resume); // Check if resume exists
				}
			}
		});

		return () => unsubscribe();
	}, []);

	// Function to filter schools based on user input
	const handleSchoolChange = (e) => {
		const value = e.target.value;
		setSchool(value);

		// Filter schools based on input
		if (value === "") {
			setFilteredSchools([]);
		} else {
			const filtered = schoolsData.schools.filter((schoolName) =>
				schoolName.toLowerCase().startsWith(value.toLowerCase())
			);

			if (filtered.length > 0) {
				setFilteredSchools(filtered);
			} else {
				setFilteredSchools([]);
			}
		}
	};

	// Function to handle selecting a school from the dropdown
	const handleSelectSchool = (schoolName) => {
		setSchool(schoolName);
		setFilteredSchools([]);
	};

	// Function to filter majors based on user input
	const handleMajorChange = (e) => {
		const value = e.target.value;
		setMajor(value);

		// Filter majors based on input
		if (value === "") {
			setFilteredMajors([]);
		} else {
			const filtered = majorsData.majors.filter((majorName) =>
				majorName.toLowerCase().startsWith(value.toLowerCase())
			);

			if (filtered.length > 0) {
				setFilteredMajors(filtered);
			} else {
				setFilteredMajors([]);
			}
		}
	};

	// Function to handle selecting a major from the dropdown
	const handleSelectMajor = (majorName) => {
		setMajor(majorName);
		setFilteredMajors([]);
	};

	// Function to filter clubs based on user input
	const handleClubsChange = (e) => {
		const value = e.target.value;
		setClubInput(value);

		if (value === "") {
			setFilteredClubs([]);
		} else {
			const filtered = clubsData.filter((club) =>
				club.name.toLowerCase().startsWith(value.toLowerCase())
			);

			if (filtered.length > 0) {
				setFilteredClubs(filtered.map((club) => club.name));
			} else {
				setFilteredClubs([]);
			}
		}
	};

	// Function to handle selecting a club from the dropdown
	const handleSelectClub = (clubName) => {
		if (!clubs.includes(clubName)) {
			setClubs([...clubs, clubName]);
		}
		setClubInput("");
		setFilteredClubs([]);
	};

	const handleAddClub = () => {
		if (clubInput && !clubs.includes(clubInput)) {
			setClubs([...clubs, clubInput]);
			setClubInput("");
			setFilteredClubs([]);
		}
	};

	const handleRemoveClub = (indexToRemove) => {
		setClubs(clubs.filter((_, index) => index !== indexToRemove));
	};

	// Function to filter locations (cities) based on user input
	const handleLocationSearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		setLocation(event.target.value);

		const filtered = locationData.filter(({ city, state }) =>
			`${city}, ${state}`.toLowerCase().includes(searchTerm)
		);

		setFilteredCities(filtered);
		setShowCityDropdown(true);
	};

	// Function to handle selecting a location (city) from the dropdown
	const handleLocationSelect = (selectedLocation) => {
		setLocation(selectedLocation);
		setShowCityDropdown(false);
	};

	// Function to filter ethnicities based on user input
	const handleEthnicitySearch = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		setEthnicity(event.target.value);

		const filtered = ethnicities.filter((ethnicity) =>
			ethnicity.toLowerCase().includes(searchTerm)
		);

		setFilteredEthnicities(filtered);
		setShowEthnicityDropdown(true);
	};

	// Function to handle selecting an ethnicity from the dropdown
	const handleEthnicitySelect = (selectedEthnicity) => {
		setEthnicity(selectedEthnicity);
		setShowEthnicityDropdown(false);
	};

	async function fetchResumeText(downloadURL, fileType) {
		try {
			const response = await fetch(downloadURL);
			if (!response.ok) {
				throw new Error(`Failed to fetch resume from ${downloadURL}`);
			}

			const arrayBuffer = await response.arrayBuffer();

			if (fileType === "application/pdf") {
				const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
				let resumeText = "";

				// Loop through all pages
				for (let i = 1; i <= pdf.numPages; i++) {
					const page = await pdf.getPage(i);
					const textContent = await page.getTextContent();
					const text = textContent.items.map((item) => item.str).join(" ");
					resumeText += text + " ";
				}

				return resumeText.trim();
			} else if (
				fileType ===
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			) {
				const result = await mammoth.extractRawText({ arrayBuffer });
				return result.value; // Extracted text from DOCX file
			} else {
				throw new Error("Unsupported file type");
			}
		} catch (error) {
			console.error("Error fetching resume text:", error);
			throw error;
		}
	}

	async function sendResumeToParse(resumeText) {
		try {
			const response = await fetch("/parse-resume", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ resumeText: resumeText }),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.status} - ${response.statusText}`);
			}

			const parsedData = await response.json();
			console.log("Parsed resume data:", parsedData);
			// Update Firestore with the resume URL
			const userDocRef = doc(db, "Students", userId);
			await updateDoc(userDocRef, {
				resumeData: parsedData,
			});
		} catch (error) {
			console.error("Failed to send resume for parsing:", error);
			throw error;
		}
	}

	const handleResumeUpload = async (event) => {
		const file = event.target.files[0];

		if (file && userId) {
			const storageRef = ref(storage, `resumes/${userId}/${file.name}`);
			const uploadTask = uploadBytesResumable(storageRef, file);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					console.log("Upload progress:", progress, "%");
				},
				(error) => {
					console.error("Error uploading resume:", error);
				},
				async () => {
					try {
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

						// Update Firestore with the resume URL
						const userDocRef = doc(db, "Students", userId);
						await updateDoc(userDocRef, {
							resume: downloadURL,
						});

						// Set the upload success state to true
						setIsResumeUploaded(true);

						// Fetch and parse resume text based on file type
						const resumeText = await fetchResumeText(downloadURL, file.type);
						sendResumeToParse(resumeText);
					} catch (error) {
						console.error(
							"Error saving resume to Firestore or fetching text:",
							error
						);
					}
				}
			);
		} else {
			console.error("No file selected or user not authenticated.");
		}
	};

	const handleHeadshotUpload = async (event) => {
		const file = event.target.files[0];

		if (file && userId) {
			const storageRef = ref(storage, `headshots/${userId}/${file.name}`);
			const uploadTask = uploadBytesResumable(storageRef, file);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					console.log("Upload progress:", progress, "%");
				},
				(error) => {
					console.error("Error uploading headshot:", error);
				},
				async () => {
					try {
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

						// Update Firestore with the headshot URL
						const userDocRef = doc(db, "Students", userId);
						await updateDoc(userDocRef, {
							headshot: downloadURL,
						});

						// Set the upload success state to true
						setIsHeadshotUploaded(true);
					} catch (error) {
						console.error("Error saving headshot to Firestore:", error);
					}
				}
			);
		} else {
			console.error("No file selected or user not authenticated.");
		}
	};

	const handleSaveProfile = async () => {
		if (!userId) {
			console.error("No user ID found.");
			return;
		}

		// Validate required fields
		if (
			!age ||
			!gender ||
			!location ||
			!ethnicity ||
			!school ||
			!major ||
			clubs.length === 0
		) {
			alert("Please fill in all required fields.");
			return;
		}

		// Create a reference to the 'Students' collection and specific user document
		const userDocRef = doc(db, "Students", userId);

		try {
			await updateDoc(userDocRef, {
				age,
				gender: gender,
				location,
				ethnicity,
				school: school,
				major: major,
				clubs: clubs,
				profileCreated: true,
			});
			alert("Profile updated successfully!");
			navigate("/students-p3");
		} catch (error) {
			console.error("Error saving profile:", error);
		}
	};

	return (
		<>
			{/* Rotate message for mobile portrait view */}
			<div className={styles.rotateMessage}>
			Please turn your phone to landscape and create your profile. Connect with students and get noticed by recruiters.
			</div>

			{/* Profile form wrapper */}
			<div className={styles.createProfileWrapper}>
				<div className={styles.sectionsWrapper}>
					<div className={styles.leftGroup}>
						<label
							htmlFor="headshot-upload"
							className={`${styles.picture} ${
								isHeadshotUploaded ? styles.uploaded : ""
							}`}
						>
							<div className={styles.headshot}>Headshot</div>
							<input
								type="file"
								id="headshot-upload"
								style={{ display: "none" }}
								accept="image/*"
								onChange={handleHeadshotUpload}
							/>
						</label>
						<label
							htmlFor="resume-upload"
							className={`${styles.resumeContainer} ${
								isResumeUploaded ? styles.uploaded : ""
							}`}
						>
							<div className={styles.resume}>Resume</div>
							<input
								type="file"
								id="resume-upload"
								style={{ display: "none" }}
								accept=".pdf,.doc,.docx"
								onChange={handleResumeUpload}
							/>
						</label>
						<div className={styles.locationDropdownContainer}>
							<input
								className={styles.locationInput}
								type="text"
								placeholder="Location"
								value={location}
								onChange={handleLocationSearch}
								onFocus={() => setShowCityDropdown(true)}
							/>
							{showCityDropdown && (
								<div className={styles.locationDropdownList}>
									{filteredCities.map(({ city, state }, index) => (
										<div
											key={index}
											className={styles.locationDropdownItem}
											onClick={() => handleLocationSelect(`${city}, ${state}`)}
										>
											{city}, {state}
										</div>
									))}
								</div>
							)}
						</div>
						<div className={styles.ethnicityDropdownContainer}>
							<input
								className={styles.ethnicityInput}
								type="text"
								placeholder="Ethnicity"
								value={ethnicity}
								onChange={handleEthnicitySearch}
								onFocus={() => setShowEthnicityDropdown(true)}
							/>
							{showEthnicityDropdown && (
								<div className={styles.ethnicityDropdownList}>
									{filteredEthnicities.map((ethnicity, index) => (
										<div
											key={index}
											className={styles.ethnicityDropdownItem}
											onClick={() => handleEthnicitySelect(ethnicity)}
										>
											{ethnicity}
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div className={styles.rightGroup}>
						{/* Education Section */}
						<div className={styles.educationSection}>
							<div className={styles.dropdownContainer}>
								<h2 className={styles.educationTitle}>Education</h2>
								<input
									type="text"
									placeholder="School"
									value={school}
									onChange={handleSchoolChange}
									className={styles.searchBar}
									required
								/>
								{filteredSchools.map((schoolName, index) => (
									<li
										key={index}
										onClick={() => handleSelectSchool(schoolName)}
										className={styles.dropdownItem}
									>
										{schoolName}
									</li>
								))}
								<input
									type="text"
									placeholder="Major"
									value={major}
									onChange={handleMajorChange}
									className={styles.searchBar}
									required
								/>
								{filteredMajors.map((majorName, index) => (
									<li
										key={index}
										onClick={() => handleSelectMajor(majorName)}
										className={styles.dropdownItem}
									>
										{majorName}
									</li>
								))}
							</div>
						</div>
						{/* Clubs and Organizations Section */}
						<div className={styles.clubSection}>
							<div className={styles.dropdownContainer}>
								<div className={styles.clubsContainer}>
									<input
										type="text"
										placeholder="Clubs and Societies"
										value={clubInput}
										onChange={handleClubsChange}
										className={styles.searchBar}
										required
									/>
									<button
										className={styles.addClubButton}
										onClick={handleAddClub}
										type="button"
									>
										+
									</button>
								</div>
								{filteredClubs.map((clubName, index) => (
									<li
										key={index}
										onClick={() => handleSelectClub(clubName)}
										className={styles.dropdownItem}
									>
										{clubName}
									</li>
								))}
								<ul className={styles.clubsList}>
									{clubs.map((club, index) => (
										<li key={index} className={styles.selectedClub}>
											<button
												className={styles.removeClubButton}
												onClick={() => handleRemoveClub(index)}
												type="button"
											>
												X
											</button>
											{club}
										</li>
									))}
								</ul>
							</div>
						</div>
						{/* Age Section */}
						<div className={styles.bottomGroup}>
							<div className={styles.ageContainer}>
								<input
									className={styles.ageInput}
									type="text"
									placeholder="Age"
									value={age}
									onChange={(e) => setAge(e.target.value)}
								/>
							</div>
							<div className={styles.genderContainer}>
								<img
									className={`${styles.genderIcon} ${
										gender === "Female" ? styles.femaleSelected : ""
									}`}
									loading="lazy"
									alt="Female"
									src="/images/female.svg"
									onClick={() => setGender("Female")}
								/>
								<img
									className={`${styles.genderIcon} ${
										gender === "Male" ? styles.maleSelected : ""
									}`}
									loading="lazy"
									alt="Male"
									src="/images/male.svg"
									onClick={() => setGender("Male")}
								/>
								<div
									className={`${styles.otherGender} ${
										gender !== "Male" && gender !== "Female" && gender !== ""
											? styles.otherSelected
											: ""
									}`}
								>
									<input
										className={styles.otherGenderInput}
										type="text"
										placeholder="Other"
										onClick={() => setGender("")}
										onChange={(e) => {
											setGender(e.target.value);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<button className={styles.saveButton} onClick={handleSaveProfile}>
					<b className={styles.createProfile}>
						{isUpdating ? "Update Profile" : "Create Profile"}
					</b>
				</button>
			</div>
		</>
	);
};

export default CreateProfile;
