import React, { useState } from 'react';
import SignUpForm from './SignUpForm';
import LoginForm from './LogInForm';
import Footer from '../../../common/Footer';
import styles from "./StudentsP1.module.css";

const StudentsP1 = () => {
  const [isSignUpMode, setIsSignUpMode] = useState(true);

  const toggleMode = () => {
    setIsSignUpMode(prevMode => !prevMode);
  };

  return (
    <div className={styles.studentsP1}>
      <div className={styles.studentsP1Child} />
      <section className={styles.contactInfo}>
        <div className={styles.mainContent}>
          <div className={styles.networkingSlogan}>
            <h1 className={styles.startNetworking}>start networking</h1>
          </div>
          <div className={styles.navigation}>
            <h2 className={styles.createYourProfile}>
              Create your profile. Connect with peers. Work on projects. Get hired.
            </h2>
          </div>
        </div>
      </section>
      <section className={styles.signUpFormWrapper}>
        <div className={styles.loginSection}>
          <b className={styles.haveAnAccountContainer}>
            <span>{isSignUpMode ? `Already have an account? ` : `Don't have an account? `}</span>
            <button onClick={toggleMode} className={styles.logIn}>
              {isSignUpMode ? "Sign In" : "Sign Up"}
            </button>
          </b>
        </div>
        {isSignUpMode ? <SignUpForm toggleMode={toggleMode}/> : <LoginForm/>}
      </section>
      <div className={styles.footerp1}>
        <Footer />
      </div>
    </div>
  );
};

export default StudentsP1;
