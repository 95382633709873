import React from "react";
import CreateProfile from "./CreateProfile";
import Footer from "../../../common/Footer";
import styles from "./StudentsP2.module.css";

const StudentsP2 = () => {
	return (
		<div className={styles.studentsP2}>
			<div className={styles.studentsP2Child} />
			<section className={styles.main}>
				<div className={styles.networking}>
					<div className={styles.resumeUpload}>
						<h1 className={styles.startNetworking}>start networking</h1>
					</div>
					<div className={styles.profileBenefits}>
						<h2
							className={styles.createYourProfile}
						>{`Create your profile. Connect with peers. Work on projects. Get hired.`}</h2>
					</div>
				</div>
			</section>
			<CreateProfile />
			<Footer />
		</div>
	);
};

export default StudentsP2;
