import PropTypes from "prop-types";
import styles from "./Footer.module.css";
import React from "react";
import { auth } from "../firebase-config";

const Footer = ({ className = "" }) => {
  // Function to handle sign out with confirmation
  const handleSignOut = () => {
    const confirmed = window.confirm("Are you sure you want to sign out?");
    if (confirmed) {
      auth.signOut()
        .then(() => {
          console.log("User signed out successfully");
          window.location.href = "/"; // Redirect to home page after sign out
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });
    }
  };

  return (
    <div className={[styles.footer, className].join(" ")}>
      <footer className={styles.footerContent}>
        <div className={styles.mainMenu}>
          <div className={styles.copyright}>
            <div className={styles.freshfroshLlcAll}>
              © 2024 FreshFrosh LLC. All rights reserved.
            </div>
          </div>
          <div className={styles.footerLinks}>
            <a
              href="https://docs.google.com/document/d/1H4KHSeNk2Mjofzb7XlHzirjnwFWhW-bUrDVwBJ-NYlY/edit"
              className={styles.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://docs.google.com/document/d/1LBborkRiDBlMHEwFL_NF0lr0w6QPEjrXRwYSegFIgzI/edit"
              className={styles.termsOfService}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            <a href="https://tally.so/r/3jLVrY" className={styles.termsOfService}>
              Referral Program
            </a>
            <a
              href="https://www.change.org/freshfrosh"
              className={styles.contactUs}
              target="_blank"
              rel="noopener noreferrer"
            >
              Support Us
            </a>
            <a
              href="/"
              className={styles.signOut}
              onClick={handleSignOut}
            >
              Sign Out
            </a>
          </div>
          <a
            href="https://www.instagram.com/freshfrosh_/#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.riinstagramFillIcon}
              loading="lazy"
              alt=""
              src="/images/instagram.svg"
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
