import React, { useState } from "react";
import SignUpForm from './SignUpForm';
import LoginForm from './LogInForm';
import Footer from '../../../common/Footer';
import styles from "./StartupsP1.module.css";

const StartupsP1 = () => {
  const [isSignUpMode, setIsSignUpMode] = useState(true);
  const [firstTime, setFirstTime] = useState(false);

  const toggleMode = () => {
    setIsSignUpMode(prevMode => !prevMode);
  };

  return (
    <div className={styles.startupsP1}>
      <div className={styles.startupsP1Child} />
      <section className={styles.main}>
        <div className={styles.content}>
          <div className={styles.recruiting}>
            <h1 className={styles.startRecruiting}>start recruiting</h1>
          </div>
          <div className={styles.profile}>
            <br></br>
            <h2 className={styles.createYourProfile}>
              Create your profile. Set requirements. Review applicants. Get results.
            </h2>
          </div>
        </div>
      </section>
      <section className={styles.signUpFormWrapper}>
        <div className={styles.loginSection}>
          <b className={styles.haveAnAccountContainer}>
            <span>{isSignUpMode ? `Already have an account? ` : `Don't have an account? `}</span>
            <button onClick={toggleMode} className={styles.logIn}>
              {isSignUpMode ? "Sign In" : "Sign Up"}
            </button>
          </b>
        </div>
        {isSignUpMode ? <SignUpForm toggleMode={toggleMode} setFirstTime={setFirstTime}/> : <LoginForm firstTime={firstTime}/>}
      </section>
      <div className={styles.footerStartupsP1}>
        <Footer />
      </div>
    </div>
  );
};

export default StartupsP1;
