import React from "react";
import Footer from "../../../common/Footer"; // Adjust the path as needed
import styles from "./AboutP2.module.css";
import { useNavigate } from "react-router-dom";

const AboutP2Fast = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.aboutP2}>
      <div className={styles.aboutP2Child} />
      <main className={styles.aboutP2Inner}>
        <section className={styles.vectorParent}>
          <img
            className={styles.vectorIcon}
            loading="lazy"
            alt=""
            src="/vector1.svg"
          />
          <div className={styles.frameParent}>
            <div className={styles.frameContainer}>
              <div className={styles.frameGroup}>
                <div className={styles.connectingStudentsWithStartWrapper}>
                  <h1 className={styles.connectingStudentsWith}>
                    connecting students with startups
                  </h1>
                </div>
                <div className={styles.description}>
                  <h1 className={styles.anAiPowered}>
                    {`an AI-powered platform for networking and recruiting`}
                  </h1>
                </div>
                <div className={styles.frameDiv}>
                  <div className={styles.ctaParent}>
                    <button className={styles.cta} onClick={() => navigate("/about-p1")}>
                      <h3 className={styles.networking}>Networking</h3>
                    </button>
                    <button className={styles.cta1} onClick={() => navigate("/about-p2")}>
                      <b className={styles.recruiting}>Recruiting</b>
                    </button>
                  </div>
                </div>
                <div className={styles.fceWrapper}>
                  <div className={styles.fce}>
                    <div className={styles.faster}>
                      <div className={styles.fasterrec} />
                      <h1 className={styles.faster1}>
                            <span>FASTER</span>
                            <ul className={styles.faster1List}>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                <li>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                            </ul>
                      </h1>
                    </div>
                    <div className={styles.cheaper}>
                      <div className={styles.cheaperrec} />
                      <div className={styles.streamlinebagDollarWrapper}>
                        <button className={styles.cheaperButton} onClick={() => navigate("/about-p2-cheaper")}>
                          <img
                            className={styles.streamlinebagDollarIcon}
                            loading="lazy"
                            alt=""
                            src="/images/bag.svg"
                          />
                        </button>
                        </div>
                        <h1 className={styles.cheaper1}>
                          <span>CHEAPER</span>
                        </h1>
                      </div>
                    <div className={styles.easier}>
                      <div className={styles.easierrec} />
                        <button className={styles.easierButton} onClick={() => navigate("/about-p2-easier")}>
                          <img
                            className={styles.akarIconsgear}
                            loading="lazy"
                            alt=""
                            src="/images/gear.svg"
                          />
                        </button>
                        <div className={styles.easierWrapper}>
                            <h1 className={styles.easier1}>
                              <span>EASIER</span>
                            </h1>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutP2Fast;
