import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  auth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  db,
  updateDoc,
  doc,
} from "../../../firebase-config";
import styles from "./SignUpForm.module.css";

const LoginForm = ({ firstTime }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError("Please verify your email before logging in.");
        setLoading(false);
        return;
      }

      // Now store the work email once the user has logged in successfully
      await updateDoc(doc(db, "Startups", user.uid), {
        workEmail: user.email,
      });

      if (firstTime) {
        navigate("/startups-p2");
      } else {
        navigate("/startups-p3");
      }
    } catch (error) {
      setError("Error during login. Please try again");
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email to reset your password.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      setError("Error sending password reset email. Please try again.");
    }
  };

  return (
    <form className={styles.signUpForm} onSubmit={handleLogin}>
      <div className={styles.emailWrapper}>
        <input
          type="email"
          placeholder="Work Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      <div className={styles.passwordWrapper}>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      <div className={styles.switchTextWrapper}>
        <p className={styles.switchText}>
          Forgot your password?{" "}
          <span className={styles.switchLink} onClick={handleForgotPassword}>
            Reset
          </span>
        </p>
      </div>
      {error && <p className={styles.error}>{error}</p>}
      {message && <p className={styles.message}>{message}</p>}
      <div className={styles.submissionButton}>
        <button type="submit" className={styles.done} disabled={loading}>
          {loading ? (
            <span className={styles.spinner}></span>
          ) : (
            <b className={styles.createProfile}>Sign In</b>
          )}
        </button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  firstTime: PropTypes.bool.isRequired,
};

export default LoginForm;
