import React, { useState, useEffect } from "react";
import DisplayProfile from "../p3/DisplayProfile";
import Footer from '../../../common/Footer';
import styles from "./StudentsP4.module.css";
import { auth, db, collection, getDocs, onAuthStateChanged, doc, updateDoc, arrayUnion, getDoc } from "../../../firebase-config"; 

const StudentsP4 = () => {
  const [students, setStudents] = useState([]); 
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
  const [commonGround, setCommonGround] = useState([]); // To store common attributes with the current student
  const [userClubs, setUserClubs] = useState([]); 
  const [userLocation, setUserLocation] = useState(""); 
  const [userAge, setUserAge] = useState(""); // To store the logged-in user's age

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, "Students", user.uid);
          const userDocSnap = await getDoc(userDocRef);

          let syncedUsersArray = [];
          if (userDocSnap.exists() && userDocSnap.data().syncedUsers) {
            syncedUsersArray = userDocSnap.data().syncedUsers;
          }

          // Store logged-in user's clubs, location, and age
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserClubs(userData.clubs || []);
            setUserLocation(userData.location || "");
            setUserAge(userData.age || ""); // Set user age
          }

          const querySnapshot = await getDocs(collection(db, "Students"));
          const allStudents = [];

          querySnapshot.forEach((doc) => {
            const studentData = doc.data();

            const ageDifference = Math.abs(parseInt(studentData.age) - parseInt(userAge));

            // Check if any of the required fields are missing
            if (
              doc.id !== user.uid &&
              !syncedUsersArray.includes(doc.id) &&
              studentData.fullName &&
              ageDifference <= 1 &&
              studentData.gender &&
              studentData.headshot &&
              studentData.school &&
              studentData.major
            ) {
              allStudents.push({
                uid: doc.id,
                fullName: studentData.fullName,
                age: studentData.age,
                gender: studentData.gender,
                picture: studentData.headshot,
                school: studentData.school,
                location: studentData.location,
                clubs: studentData.clubs || [], 
                major: studentData.major
              });
            }
          });

          setStudents(allStudents);
        } catch (error) {
          console.error("Error fetching student data: ", error);
        }
      }
    });

    return () => unsubscribe();
  }, [userAge]); 

  const handleSkip = () => {
    if (students.length > 0) {
      setCurrentStudentIndex((prevIndex) => (prevIndex + 1) % students.length);
    }
  };

  const handleSync = async () => {
    const currentStudent = students[currentStudentIndex];
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "Students", user.uid);

        await updateDoc(userDocRef, {
          syncedUsers: arrayUnion(currentStudent.uid)
        });

        setStudents((prevStudents) =>
          prevStudents.filter((student) => student.uid !== currentStudent.uid)
        );
        setCurrentStudentIndex(0); 
        alert(`You have synced with ${currentStudent.fullName}`);
      }
    } catch (error) {
      console.error("Error syncing user: ", error);
    }
  };

  useEffect(() => {
    if (students.length > 0) {
      const currentStudent = students[currentStudentIndex];
      const commonAttributes = [];

      const commonClubs = currentStudent.clubs.filter(club => userClubs.includes(club));
      if (commonClubs.length > 0) {
        commonAttributes.push(`You're Both Part Of The Following Clubs: ${commonClubs.join(", ")}`);
      }

      if (currentStudent.location === userLocation) {
        commonAttributes.push("You Both Are Located In The Same City");
      }


      setCommonGround(commonAttributes);
    }
  }, [currentStudentIndex, students, userClubs, userLocation, userAge]);

  if (students.length === 0) {
    return <div>Loading student profiles...</div>;
  }

  const currentStudent = students[currentStudentIndex]; 

  return (
    <div className={styles.studentsP4}>
      <div className={styles.studentsP4Child} />
      <section className={styles.studentsP4Inner}>
        <div className={styles.frameParent}>
          <div className={styles.startNetworkingWrapper}>
            <h1 className={styles.startNetworking}>start networking</h1>
          </div>
          <div className={styles.createYourProfileConnectWWrapper}>
            <h2 className={styles.createYourProfile}>
              {`Create your profile. Connect with peers. Work on projects. Get hired.`}
            </h2>
          </div>
        </div>
      </section>
      <section className={styles.frameSection}>
        <div className={styles.frameGroup}>
          <div className={styles.pictureParent}>
            <DisplayProfile 
              name={currentStudent.fullName} 
              age={currentStudent.age}  
              gender={currentStudent.gender}  
              profilePictureURL={currentStudent.picture} 
              school={currentStudent.school} 
              major={currentStudent.major}  
            />
            <div className={styles.doneParent}>
              <button className={styles.done} onClick={handleSkip}>
                <h2 className={styles.createProfile}>Skip</h2>
              </button>
              <button className={styles.done1} onClick={handleSync}>
                <h2 className={styles.createProfile1}>Sync</h2>
              </button>
              
            </div>
          </div>
          <div className={styles.pictureGroup}>
            <div className={styles.picture}>
              <h2 className={styles.commonGround}>Common Ground</h2>
              <div className={styles.comingSoonLabels}>
                {commonGround.length > 0 ? (
                  commonGround.map((attribute, index) => (
                    <div key={index}>
                      <i className={styles.featureComingSoon}>{attribute}</i>
                    </div>
                  ))
                ) : (
                  <i className={styles.featureComingSoon}>No common attributes</i>
                )}
              </div>
            </div>
            <div className={styles.picture1}>
              <h2 className={styles.commonGround}>Project Ideas</h2>
              <div className={styles.featureComingSoonWrapper}>
                <i className={styles.featureComingSoon}>feature coming soon</i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default StudentsP4;
