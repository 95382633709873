import Footer from "../../common/Footer";
import PropTypes from "prop-types";
import styles from "./Benefits.module.css";
import React from "react";

const Benefits = ({ className = "" }) => {
  return (
    <section className={[styles.advantages, className].join(" ")}>
      <div className={styles.benefits}>
        <div className={styles.values}>
          <h1 className={styles.fAS}>F A S T E R</h1>
          <h1 className={styles.fAS}>C H E A P E R</h1>
          <h1 className={styles.fAS}>E A S I E R</h1>
        </div>
      </div>
      <Footer />
    </section>
  );
};

Benefits.propTypes = {
  className: PropTypes.string,
};

export default Benefits;
