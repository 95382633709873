import React, { useState, useEffect } from "react";
import { db, setDoc, doc } from "../../../firebase-config";
import { auth } from "../../../firebase-config";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import styles from "./Registration.module.css";

const Registration = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [fundingStage, setFundingStage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate(); // useNavigate hook for redirection

  // Track the logged-in user and set the userId
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid); // Set the userId to the logged-in user's UID
      }
    });
    return () => unsubscribe(); // Cleanup the subscription on component unmount
  }, []);

  const handleRegistration = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);
    setError("");
    setSuccessMessage("");

    if (!userId) {
      setError("No user is logged in. Please log in first.");
      setLoading(false);
      return;
    }

    try {
      const userDocRef = doc(db, "Startups", userId); // Reference to the user's document

      // Add Job Title, Company Website, and Funding Stage to the existing document (merge: true)
      await setDoc(userDocRef, {
        jobTitle,
        companyWebsite,
        fundingStage,
      }, { merge: true }); // Use merge: true to update the document without overwriting previous data

      setSuccessMessage("Information added successfully");

      // After successful submission, navigate to StartupsP3
      navigate("/startups-p3");
    } catch (error) {
      setError("Error saving information. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.registrationForm} onSubmit={handleRegistration}>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          placeholder="Job Title"
          value={jobTitle}
          onChange={(event) => setJobTitle(event.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      <div className={styles.inputWrapper}>
        <input
          type="url"
          placeholder="Company Website"
          value={companyWebsite}
          onChange={(event) => setCompanyWebsite(event.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          placeholder="Funding Stage"
          value={fundingStage}
          onChange={(event) => setFundingStage(event.target.value)}
          className={styles.input}
          required
          disabled={loading}
        />
      </div>
      {error && <p className={styles.error}>{error}</p>}
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      <div className={styles.submissionButton}>
        <button
          type="submit"
          className={styles.done}
          disabled={loading}
        >
          {loading ? <span className={styles.spinner}></span> : <b>Submit</b>}
        </button>
      </div>
    </form>
  );
};

export default Registration;
