import React, { useState, useEffect, useRef } from "react";
import styles from "./ChatBot.module.css";
import {
	auth,
	db,
	collection,
	getDocs,
	onAuthStateChanged,
	doc,
	updateDoc,
	arrayUnion,
	getDoc,
} from "../firebase-config";
import DisplayProfile from "../pages/students/p3/DisplayProfile";

export default function ChatBot() {
	const [students, setStudents] = useState([]);
	const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
	const [messages, setMessages] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [isTyping, setIsTyping] = useState(false);
	const [isLoading, setIsLoading] = useState(true); // Loading state
	const chatDisplayRef = useRef(null); // Reference for auto-scrolling

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				try {
					const userDocRef = doc(db, "Students", user.uid);
					const userDocSnap = await getDoc(userDocRef);
					let syncedUsersArray = [];
					if (userDocSnap.exists() && userDocSnap.data().syncedUsers) {
						syncedUsersArray = userDocSnap.data().syncedUsers;
					}

					const querySnapshot = await getDocs(collection(db, "Students"));
					const allStudents = [];

					querySnapshot.forEach((doc) => {
						const studentData = doc.data();

						// Check if any of the required fields are missing
						if (
							doc.id !== user.uid &&
							!syncedUsersArray.includes(doc.id) &&
							studentData.fullName &&
							studentData.age &&
							studentData.gender &&
							studentData.headshot &&
							studentData.school &&
							studentData.major &&
							studentData.clubs&&
							studentData.resumeData
						) {
							allStudents.push({
								uid: doc.id,
								fullName: studentData.fullName,
								age: studentData.age,
								gender: studentData.gender,
								picture: studentData.headshot,
								school: studentData.school,
								major: studentData.major,
								clubs: studentData.clubs,
								resumeData: studentData.resumeData,
							});
						}
					});

					if (allStudents.length > 0) {
						// Set the initial messages for the first student
						setMessages([
							{
								text: `Hello, my name is ${allStudents[0].fullName}.`,
								sender: "bot",
							},
						]);
					}
					setStudents(allStudents);
					setIsLoading(false); // Stop loading
				} catch (error) {
					console.error("Error fetching student data: ", error);
					setIsLoading(false); // Stop loading if error occurs
				}
			}
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		// Auto-scroll to the bottom when new messages are added
		if (chatDisplayRef.current) {
			chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
		}
	}, [messages]);

	const handleSkip = () => {
		if (students.length > 1) {
			setCurrentStudentIndex((prevIndex) => {
				const nextIndex = (prevIndex + 1) % students.length;
				// Reset messages with the next student's name
				setMessages([
					{
						text: `Hello, my name is ${students[nextIndex].fullName}.`,
						sender: "bot",
					},
				]);
				return nextIndex;
			});
		} else {
			setStudents([]); // No more students left to review
			setMessages([]); // Clear messages if no students are left
		}
	};

	const handleSync = async () => {
		const currentStudent = students[currentStudentIndex];
		try {
			const user = auth.currentUser;
			if (user) {
				const userDocRef = doc(db, "Startups", user.uid);

				await updateDoc(userDocRef, {
					syncedUsers: arrayUnion(currentStudent.uid),
				});

				setStudents((prevStudents) =>
					prevStudents.filter((student) => student.uid !== currentStudent.uid)
				);
				setCurrentStudentIndex(0);

				// Reset messages for the next student
				if (students.length > 1) {
					setMessages([
						{
							text: `Hello, my name is ${students[0].fullName}.`,
							sender: "bot",
						},
					]);
				} else {
					setMessages([]);
				}

				console.log(`Successfully synced with ${currentStudent.fullName}.`);
			}
		} catch (error) {
			console.error("Error syncing user: ", error);
		}
	};

	if (isLoading) {
		return (
			<div className={styles.loadingContainer}>
				<div className={styles.spinner}></div>
			</div>
		);
	}

	if (students.length === 0) {
		return <div>No students available for syncing.</div>;
	}

	const currentStudent = students[currentStudentIndex];

	const handleSubmit = () => {
		if (inputValue.trim()) {
			// Add the user's message to the messages state
			const newMessage = { text: inputValue, sender: "user" };
			setMessages((prevMessages) => [...prevMessages, newMessage]);
			setInputValue(""); // Clear the input field after sending the message
			// Handle bot response (this can be connected to an API or simple logic)
			handleBotResponse(inputValue);
		}
	};

	const handleBotResponse = async (userInput) => {
		setIsTyping(true); // Show the typing indicator
		const currentStudent = students[currentStudentIndex];
		const name = currentStudent.fullName;
		const school = currentStudent.school;
		const major = currentStudent.major;
		const clubs = currentStudent.clubs;
		const resumeData = currentStudent.resumeData;

		try {
			const response = await fetch("/chatbot", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ message: userInput, name, school, major, clubs, resumeData}),
			});

			const data = await response.json();
			const botResponse = data.response;

			const newBotMessage = { text: botResponse, sender: "bot" };
			setMessages((prevMessages) => [...prevMessages, newBotMessage]);
		} catch (error) {
			const errorMessage = {
				text: "Oops, something went wrong!",
				sender: "bot",
			};
			setMessages((prevMessages) => [...prevMessages, errorMessage]);
		} finally {
			setIsTyping(false); // Hide the typing indicator
		}
	};

	return (
		<div className={styles.botWrapper}>
			<div className={styles.studentCarousel}>
				<div className={styles.pictureParent}>
					<DisplayProfile
						name={currentStudent.fullName}
						age={currentStudent.age}
						gender={currentStudent.gender}
						profilePictureURL={currentStudent.picture}
						school={currentStudent.school}
						major={currentStudent.major}
					/>
					<div className={styles.carouselNavigationContainer}>
						<button className={styles.carouselButtons} onClick={handleSkip}>
							<h2 className={styles.createProfile}>Skip</h2>
						</button>
						<button className={styles.carouselButtons} onClick={handleSync}>
							<h2 className={styles.createProfile1}>Sync</h2>
						</button>
					</div>
				</div>
			</div>
			<div className={styles.chatWrapper}>
				<div className={styles.chatDisplay} ref={chatDisplayRef}>
					{messages.map((message, index) => (
						<div
							key={index}
							className={
								message.sender === "user"
									? styles.userMessage
									: styles.botMessage
							}
						>
							{message.text}
						</div>
					))}
					{isTyping && (
						<div className={styles.typingIndicator}>Bot is typing...</div>
					)}
				</div>
				<div className={styles.inputContainer}>
					<textarea
						className={styles.chatInput}
						placeholder="send message"
						rows="2"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								handleSubmit();
							}
						}}
					/>
					<button className={styles.submitButton} onClick={handleSubmit}>
						Enter
					</button>
				</div>
			</div>
		</div>
	);
}
