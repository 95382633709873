import React, { useState, useEffect } from "react";
import { auth, db, doc, getDoc, onAuthStateChanged } from "../../../firebase-config";
import DisplayProfile from "./DisplayProfile"; 

const UserProfile = () => {
  const [fullName, setFullName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [picture, setPicture] = useState("");
  const [school, setSchool] = useState("");
  const [major, setMajor] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const docRef = doc(db, "Students", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setFullName(userData["fullName"]);
            setAge(userData.age);
            setGender(userData.gender); 
            setPicture(userData.headshot);
            setSchool(userData.school);
            setMajor(userData.major)
          } else {
            console.log("No such document.");
          }
        } catch (error) {
          console.error("Error fetching document: ", error);
        }
      } else {
        console.log("No user is signed in.");
      }
    });


    return () => unsubscribe();
  }, []);

  return <DisplayProfile name={fullName} age={age} gender={gender} profilePictureURL={picture} school={school} major={major}/>; 
};

export default UserProfile;
