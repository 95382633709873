import React, { createContext, useState, useEffect } from "react";
import { auth, db, doc, getDoc } from "../firebase-config"; // Import your Firebase auth and Firestore instances
import PropTypes from "prop-types";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accountType, setAccountType] = useState(null); // Store account type

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in, now determine if they are a student or a startup
        const studentDoc = await getDoc(doc(db, "Students", user.uid));
        const startupDoc = await getDoc(doc(db, "Startups", user.uid));

        if (studentDoc.exists()) {
          setAccountType("student");
        } else if (startupDoc.exists()) {
          setAccountType("startup");
        }

        setUser(user);
      } else {
        setUser(null);
        setAccountType(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, accountType }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
