import React, { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext"; // Import AuthContext
import { db, doc, getDoc } from "../firebase-config";
import styles from "./Navbar.module.css";

const Navbar = () => {
  const { accountType, user } = useContext(AuthContext); // Access accountType from AuthContext
  const [isOpen, setIsOpen] = useState(false);
  const [profileCreated, setProfileCreated] = useState(null); // Null initially to represent unverified state
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchProfileStatus = async () => {
      if (user && accountType) {
        try {
          const collection = accountType === "student" ? "Students" : "Startups";
          const userDocRef = doc(db, collection, user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setProfileCreated(userData.profileCreated || false); // Set profileCreated status
          } else {
            setProfileCreated(false); // Default to false if document does not exist
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          setProfileCreated(false); // Handle errors gracefully
        }
      }
    };

    fetchProfileStatus();
  }, [user, accountType]);

  // Handle Profile icon click, redirecting based on account type
  const handleProfileClick = async () => {
    if (!user) {
      navigate("/"); // If no user is logged in, redirect to home
      return;
    }

    try {
      // Dynamically choose the collection based on accountType
      const collection = accountType === "student" ? "Students" : "Startups";
      const userDocRef = doc(db, collection, user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        if (accountType === "student") {
          navigate("/students-p2");
        } else if (accountType === "startup") {
          navigate("/startups-p3", { state: { isUpdating: true } });
        }
      } else {
        console.error("User document does not exist");
        navigate("/students-p2"); // Default to profile creation if no document is found
      }
    } catch (error) {
      console.error("Error fetching user document:", error);
      navigate("/"); // Redirect to home in case of an error
    }
  };

  return (
    <header className={styles.main}>
      <div className={styles.freshfroshParent}>
        <NavLink to="/" className={styles.freshfrosh} onClick={closeMenu}>
          FreshFrosh
        </NavLink>
        <nav className={`${styles.content} ${isOpen ? styles.open : ""}`}>
          <ul className={styles.navList}>
            <li className={styles.navItem}>
              <NavLink
                to="/"
                className={styles.navLink}
                activeclassname={styles.active}
                onClick={closeMenu}
              >
                Home
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink
                to="/about-p1"
                className={styles.navLink}
                activeclassname={styles.active}
                onClick={closeMenu}
              >
                About
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink
                to={
                  accountType === "student"
                    ? profileCreated === true
                      ? "/students-p3" // Redirect to the main student page if the profile is created
                      : "/students-p2" // Redirect to profile creation page if not created
                    : "/students-p1"
                }
                className={styles.navLink}
                activeclassname={styles.active}
                onClick={closeMenu}
              >
                Students
              </NavLink>
            </li>
            <li className={styles.navItem}>
              <NavLink
                to={accountType === "startup" ? "/startups-p3" : "/startups-p1"}
                className={styles.navLink}
                activeclassname={styles.active}
                onClick={closeMenu}
              >
                Startups
              </NavLink>
            </li>

            <li className={styles.navItem}>
              <NavLink
                to="/contact"
                className={styles.navLink}
                activeclassname={styles.active}
                onClick={closeMenu}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
        {/* Redirect based on account type when profile icon is clicked */}
        <div className={styles.graphics} onClick={handleProfileClick}>
          <img
            className={styles.vectorIcon}
            src="/images/profile.svg"
            alt="Profile"
            loading="lazy"
          />
        </div>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
