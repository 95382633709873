import { initializeApp } from "firebase/app";
import {
	getAuth,
	RecaptchaVerifier,
	signInWithPhoneNumber,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	onAuthStateChanged,
} from "firebase/auth";
import {
	getFirestore,
	setDoc,
	doc,
	updateDoc,
	getDoc,
	getDocs, // Import getDocs for reading documents
	collection, // Import collection for querying collections
	arrayUnion, // Import arrayUnion for updating array fields in Firestore
} from "firebase/firestore";
import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
} from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyCuuLYN9cNTQhJtmIQtC_etfpN4eQkVcVA",
	authDomain: "freshfrosh-67198.firebaseapp.com",
	projectId: "freshfrosh-67198",
	storageBucket: "freshfrosh-67198.appspot.com",
	messagingSenderId: "1040414631974",
	appId: "1:1040414631974:web:9334af2d175916ea701ee7",
	measurementId: "G-N0KSBCM78D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

const storage = getStorage(app); // Initialize Firebase Storage

export {
	auth,
	RecaptchaVerifier,
	signInWithPhoneNumber,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	db,
	setDoc,
	doc,
	updateDoc,
	getDoc,
	getDocs, // Export getDocs
	collection, // Export collection
	arrayUnion, // Export arrayUnion for updating array fields
	onAuthStateChanged,
	storage, // Export storage
	ref, // Export ref to create storage references
	uploadBytesResumable, // Export uploadBytesResumable to handle file uploads
	getDownloadURL, // Export getDownloadURL to retrieve the file URL after upload
};
