import React from "react";
import Registration from './Registration';
import Footer from '../../../common/Footer';
import styles from "./StartupsP2.module.css";

const StartupsP2 = () => {

  return (
    <div className={styles.startupsP1}>
      <div className={styles.startupsP1Child} />
      <section className={styles.main}>
        <div className={styles.content}>
          <div className={styles.recruiting}>
            <h1 className={styles.startRecruiting}>start recruiting</h1>
          </div>
          <div className={styles.profile}>
            <br></br>
            <h2 className={styles.createYourProfile}>
              Create your profile. Set requirements. Review applicants. Get results.
            </h2>
          </div>
        </div>
      </section>
      <Registration />
      <div className={styles.footerStartupsP2}>
        <Footer />
      </div>
    </div>
  );
};

export default StartupsP2;
