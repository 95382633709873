import React, { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation } from "react-router-dom";
import { AuthProvider } from "./common/AuthContext";
import Navbar from "./common/Navbar";
import Home from "./pages/home/Home";
import Admin from "../src/pages/admin/Admin";
import Contact from "./pages/contact/Contact";

// About Pages
import AboutP1 from "./pages/about/p1/AboutP1";
import AboutP1Fast from "./pages/about/p1/AboutP1Fast";
import AboutP1Cheaper from "./pages/about/p1/AboutP1Cheaper";
import AboutP1Easier from "./pages/about/p1/AboutP1Easier";
import AboutP2 from "./pages/about/p2/AboutP2";
import AboutP2Fast from "./pages/about/p2/About2Fast"; // Fixed typo
import AboutP2Cheap from "./pages/about/p2/AboutP2Cheap";
import AboutP2Easy from "./pages/about/p2/AboutP2Easy";

// Students Pages
import StudentsP1 from "./pages/students/p1/StudentsP1";
import StudentsP2 from "./pages/students/p2/StudentsP2";
import StudentsP3 from "./pages/students/p3/StudentsP3";
import StudentsP4 from "./pages/students/p4/StudentsP4";

// Startups Pages
import StartupsP1 from "./pages/startups/p1/StartupsP1";
import StartupsP2 from "./pages/startups/p2/StartupsP2";
import StartupsP3 from "./pages/startups/p3/StartupsP3";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    document.title = "FreshFrosh"; // Always set title to "FreshFrosh"
    
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute("content", "connecting students with startups");
    }
  }, []); // Only run once when the app loads

	return (
		<AuthProvider>
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about-p1" element={<AboutP1 />} />
				<Route path="/about-p2" element={<AboutP2 />} />
				<Route path="/admin" element={<Admin />} />
				<Route path="/students-p1" element={<StudentsP1 />} />
				<Route path="/students-p2" element={<StudentsP2 />} />
				<Route path="/students-p3" element={<StudentsP3 />} />
				<Route path="/students-p4" element={<StudentsP4 />} />
				<Route path="/startups-p1" element={<StartupsP1 />} />
				<Route path="/startups-p2" element={<StartupsP2 />} />
				<Route path="/startups-p3" element={<StartupsP3 />} />
				<Route path="/about-p1-fast" element={<AboutP1Fast />} />
				<Route path="/about-p1-cheaper" element={<AboutP1Cheaper />} />
				<Route path="/about-p1-easier" element={<AboutP1Easier />} />
				<Route path="/about-p2-fast" element={<AboutP2Fast />} />
				<Route path="/about-p2-cheaper" element={<AboutP2Cheap />} />
				<Route path="/about-p2-easier" element={<AboutP2Easy />} />
				<Route path="/contact" element={<Contact />} />
			</Routes>
		</AuthProvider>
	);
}

export default App;
