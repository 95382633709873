import React from "react";
import Footer from "../../../common/Footer"; // Adjust the path as needed
import styles from "./AboutP1.module.css";
import { useNavigate } from "react-router-dom";

const AboutP1 = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.aboutP1}>
      <div className={styles.aboutP1Child} />
      <main className={styles.main}>
        <section className={styles.content}>
          <img
            className={styles.vectorIcon}
            loading="lazy"
            alt=""
            src="/vector.svg"
          />
          <div className={styles.columns}>
            <div className={styles.hero}>
              <div className={styles.sloganParent}>
                <div className={styles.slogan}>
                  <h1 className={styles.connectingStudentsWith}>
                    connecting students with startups
                  </h1>
                </div>
                <div className={styles.description}>
                  <h1 className={styles.anAiPowered}>
                    {`an AI-powered platform for networking and recruiting`}
                  </h1>
                </div>
                <div className={styles.frameWrapper}>
                  <div className={styles.ctaParent}>
                    <button className={styles.cta}>
                      <b className={styles.networking}>Networking</b>
                    </button>
                    <button className={styles.cta1} onClick={() => navigate('/about-p2')}>
                      <b className={styles.recruiting}>Recruiting</b>
                    </button>
                  </div>
                </div>
                <div className={styles.benefits}>
                  <div className={styles.fce}>
                    <div className={styles.faster}>
                      <div className={styles.fasterrec} />
                        <img
                          className={styles.phlightningBoldIcon}
                          loading="lazy"
                          alt=""
                          src="/images/bolt.svg"
                        />
                        <div className={styles.fasterWrapper}>
                          <h1 className={styles.faster1}>
                            <span className={styles.faster1Span}>FASTER</span>
                          </h1>
                        </div>
                    </div>
                    <div className={styles.cheaper}>
                      <div className={styles.cheaperrec} />
                      <div className={styles.streamlinebagDollarWrapper}>
                        <img
                          className={styles.streamlinebagDollarIcon}
                          loading="lazy"
                          alt=""
                          src="/images/bag.svg"
                        />
                      </div>
                      <h1 className={styles.cheaper1}>
                        <span>CHEAPER</span>
                      </h1>
                    </div>
                    <div className={styles.easier}>
                      <div className={styles.easierrec} />
                        <img
                          className={styles.akarIconsgear}
                          loading="lazy"
                          alt=""
                          src="/images/gear.svg"
                        />
                      <div className={styles.easierWrapper}>
                        <h1 className={styles.easier1}>
                          <span>EASIER</span>
                          </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutP1;

