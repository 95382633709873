import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Benefits from "./Benefits";
import styles from "./Home.module.css";
import { AuthContext } from "../../common/AuthContext";

const Home = () => {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);

	return (
		<div className={styles.home}>
			<div className={styles.homeChild} />
			<section className={styles.sloganWrapper}>
				<div className={styles.slogan}>
					<div className={styles.wereRedefiningTheWayStudeWrapper}>
						<h1 className={styles.wereRedefiningTheContainer}>
							<span>{`We’re redefining the way students `}</span>
							<span className={styles.network}>network</span>
							<span>{` and startups `}</span>
							<span className={styles.recruit}>recruit</span>
							<span> one connection at a time.</span>
						</h1>
					</div>
				</div>
			</section>
			<section className={styles.shapes}>
				<div className={styles.frames}>
					<div className={styles.types}>
						<img loading="lazy" alt="Graduation Cap" src="/images/grad.svg" />
					</div>
					<div className={styles.types}>
						<img loading="lazy" alt="Rocket Ship" src="/images/rocket.svg" />
					</div>
				</div>
			</section>
			<div className={styles.actions}>
				<div className={styles.buttons}>
					{!user ? (
						<>
							<button
								className={styles.cta}
								onClick={() => navigate("/students-p1")}
							>
								<b className={styles.imAStudent}>For Students</b>
							</button>
						</>
					) : (
						<>
							<button
								className={styles.cta}
								onClick={() => navigate("/students-p3")}
							>
								<b className={styles.imAStudent}>For Students</b>
							</button>
						</>
					)}
					<button
						className={styles.cta}
						onClick={() => navigate("/startups-p1")}
					>
						<b className={styles.imAStartup}>For Startups</b>
					</button>
				</div>
			</div>
			<Benefits />
		</div>
	);
};

export default Home;
